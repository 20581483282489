var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading && _vm.data.filed_content != 'Hide')?_c('div',{style:(_vm.getElementStyle)},[_c('el-row',{attrs:{"align":"middle"}},[(
        !_vm.data.properties.hideLabel && _vm.data.properties.filed_content != 'Hide'
      )?_c('el-col',{style:(_vm.computedStyles),attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 4 : _vm.data.description ? 20 : 24}},[(_vm.hasLabel)?_c('label',{style:(_vm.getLabelStyles),attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.label || _vm.data.label))]):_vm._e(),(_vm.data.validations.required && _vm.hasLabel && !_vm.isView)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e(),_c('span',[(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1)]):_c('el-col',{ref:"colRef",style:(_vm.computedStyles),attrs:{"span":_vm.showLabel ? 4 : _vm.data.description ? 20 : 24}},[(_vm.hasLabel)?_c('span',{staticStyle:{"color":"white","display":"inline-block","height":"20px","opacity":"0"}}):_vm._e(),(_vm.data.validations.required && _vm.hasLabel && !_vm.isView)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e()]),_c('el-col',{staticClass:"execute-view",attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 12 : 24}},[(
          !_vm.isView &&
          _vm.data.properties.filed_content != 'Hide' &&
          !_vm.isList &&
          _vm.getDateViewType
        )?_c('el-date-picker',{attrs:{"disabled":_vm.isFromDocument ? false : _vm.checkReadonly(),"type":_vm.getDateViewType,"placeholder":_vm.data.placeholder},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e(),(
          _vm.isList &&
          !_vm.isView &&
          _vm.data.properties.filed_content != 'Hide' &&
          !_vm.getDateViewType
        )?_c('el-date-picker',{style:(_vm.getStyle),attrs:{"prefix-icon":"el-icon-date","readonly":_vm.readonly(),"type":"date","placeholder":_vm.data.placeholder || _vm.data.label,"disabled":_vm.isFromDocument ? false : _vm.checkReadonly(),"format":_vm.getDefaultDateFormat && _vm.getDefaultDateFormat.includes('Do') == false
            ? _vm.getDefaultDateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd')
            : 'MM-dd-yyyy',"picker-options":_vm.pickerOptions},on:{"change":function($event){return _vm.applyFormRules(_vm.time)}},model:{value:(_vm.form[this.data.key]),callback:function ($$v) {_vm.$set(_vm.form, this.data.key, $$v)},expression:"form[this.data.key]"}}):_vm._e(),(
          !_vm.isView &&
          !_vm.isList &&
          _vm.data.properties.filed_content != 'Hide' &&
          !_vm.getDateViewType
        )?_c('el-date-picker',{class:{ 'highlighted-border': _vm.highlight },style:(_vm.getStyle),attrs:{"prefix-icon":"el-icon-date","type":"date","placeholder":_vm.data.placeholder || _vm.data.label,"disabled":_vm.isFromDocument ? false : _vm.checkReadonly(),"format":_vm.getDefaultDateFormat && _vm.getDefaultDateFormat.includes('Do') == false
            ? _vm.getDefaultDateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd')
            : 'MM-dd-yyyy',"readonly":_vm.readonly(),"picker-options":_vm.pickerOptions},on:{"change":_vm.applyFormRules},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e(),(_vm.isView && _vm.data.properties.filed_content != 'Hide')?_c('p',{style:(_vm.getStyle)},[(_vm.form[_vm.data.key])?[(
              _vm.data &&
              _vm.data.date_view_type &&
              ['month', 'year'].includes(_vm.data.date_view_type)
            )?[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.form[_vm.data.key],_vm.data.date_view_type == 'year' ? 'YYYY' : 'MMMM YYYY'))+" ")]:[_vm._v(" "+_vm._s(_vm._f("globalDateFormat")(_vm.form[_vm.data.key]))+" ")]]:[_vm._v(_vm._s(_vm.data.properties && _vm.data.properties.tableDefaultValue && _vm.data.properties.tableDefaultValue !== '' ? _vm.data.properties.tableDefaultValue : '-'))]],2):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }